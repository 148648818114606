import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['sendButton'];

  initialize() {
    const icons = document.querySelectorAll('.valid_icon');
    icons.forEach((icon) => {
      if (icon.dataset.display === 'true') {
        icon.classList.remove('hidden');
      }
    });
  }


  saveProduct() {
    const sendButton = this.sendButtonTarget;    
    const domRing = '<div class="loading-ring" id="loading-ring"></div>';
    const loadingRing = document.getElementById('loading-ring');
    
    this.insertHtml(sendButton, 'beforebegin', domRing);

    setTimeout(() => {
      loadingRing.remove();
    }, 1000);
    
    icon.classList.remove('hidden');
  }

  insertHtml = (target, position, domItem) => {
    target.insertAdjacentHTML(position, domItem);
  };
}
