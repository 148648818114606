import consumer from "./consumer";

consumer.subscriptions.create(
  { channel: "UserChannel" },
  {
    received(data) {
      this.handleAction(data.action, data.redirectLink, data.id);
    },

    handleAction(action, redirectLink, id) {
      switch (action) {
        case "show":
          this.handleShow(redirectLink);
        case "update":
          this.handleAfterUpdate(redirectLink, id);
          break;
        default:
          break;
      }
    },

    handleShow(redirectLink) {
      const regex = /^\/leads\/\d*$/;
      const path = window.location.pathname;
      if (!path.match(regex)) {
        const modal = $("#redirectLeadModal")[0];
        const link = document.getElementById("redirectLink");
        if (link !== null) {
          link.href = redirectLink;
          modal.classList.remove("modal--hidden");
        }
      }
    },

    handleAfterUpdate(redirectLink, id) {
      const regex = new RegExp("^/leads/" + id + "*$", "g");
      const path = window.location.pathname;
      if (path.match(regex)) {
        window.location.href = "/";
      }
    },
  }
);
