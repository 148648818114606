  import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["options","option"]

  connect() {

  }

  add_option(event){
    const event_type = event.srcElement.dataset.type
    let option_clone = this.optionTarget.cloneNode(true)
    option_clone.lastChild.className = option_clone.lastChild.className.replace("hidden","")
    option_clone.children[1].value=""
    option_clone.children[2]
    option_clone.firstChild.className = option_clone.firstChild.className.replace("good",event_type)
    option_clone.firstChild.firstChild.value = this.option_score(event_type)

    this.optionsTarget.append(option_clone);
  }

  delete_option(event){
    if(event.target.parentNode.parentNode.children.length <= 1)return
    event.target.parentNode.remove()
  }

  option_score(event){
    switch (event) {
      case "good":
        return 1
      case "bad":
        return -1
      default:
        return 0
    }
  }
}
