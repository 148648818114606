import { Controller } from 'stimulus';

import introJs from 'intro.js';

export default class extends Controller {
  static targets = [
    'portfolio',
    'objective',
    'addLead',
    'dashboard',
    'alerts',
    'call',
    'qualify',
    'status',
  ];

  connect() {
    if (gon.should_onboard) {
      this.onboardingTutorial();
    } else if (gon.lead_action === 'show') {
      this.leadShowTutorial();
    } else if (gon.lead_action === 'edit') {
      this.leadEditTutorial();
    }
  }

  onboardingTutorial() {
    const toggleSideBars = this.toggleSideBars;
    introJs()
      .onafterchange(function (targetElement) {
        if (gon.is_mobile) {
          toggleSideBars(this._currentStep);
        }
      })
      .oncomplete(() => {
        this.toggleSideBars(6);
      })
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: true,
        doneLabel: "C'EST PARTI !",
        nextLabel: 'SUIVANT',
        prevLabel: 'Précédent',
        steps: [
          {
            title: 'Bienvenue sur Sqwad',
            intro:
              "C'est ici que tu vas pouvoir suivre et gérer tes leads, en demander de nouveaux, suivre tes statistiques, tes gains et accéder à toutes les infos importantes de ta mission.",
          },
          {
            element: this.portfolioTarget,
            intro:
              "Tu retrouveras ici tous les <b>leads</b> que tu es en train de traiter. Tous les leads de ton portefeuille ont un statut ce qui te permet de savoir <b>où tu en es dans le process de traitement</b> et les actions qu'il te reste à réaliser.",
          },
          {
            element: this.objectiveTarget,
            intro:
              "En début de mois, tu définis un <b>objectif de rémunération</b> pour le mois à venir. Cet objectif n'a qu'un but indicatif, il n'impactera pas ta rémunération mais te permet de <b>savoir où tu en es</b> par rapport à ton objectif de début de mois. Vois-le comme un challenge !",
          },
          {
            element: this.addLeadTarget,
            intro:
              "Ce bouton te permet de demander un <b>nouveau lead</b> à traiter. Une fois que le lead t'es attribué, <b>tu dois le traiter jusqu'au bout !</b> Pour pouvoir demander un nouveau lead il faut avoir validé ton call test et avoir réalisé toutes les actions de la journée sur les leads de ton portefeuille. Si tu ne peux pas demander de leads et que tu ne comprends pas pourquoi, tourne-toi vers <b>le manager de ta mission</b> sur Whatsapp.",
          },
          {
            element: this.dashboardTarget,
            intro:
              "Cet onglet te permet de suivre tes <b>statistiques, performances et gains !</b> Il te servira notamment pour <b>vérifier tes gains</b> du mois lors de l'envoi de ta facture.",
          },
          {
            element: gon.is_mobile ? this.alertsTarget : this.alertsTargets[1],
            intro:
              'Garde un œil sur tes <b>notifications</b> pour ne rien manquer !',
          },
          {
            title: "Place à l'action !",
            intro:
              "Bravo, tu es maintenant fin prêt.e pour passer à l'action ! On est très heureux que tu rejoignes l'aventure ! N'oublie pas, <b>on reste disponible</b> si tu as le moindre souci !",
          },
        ],
      })
      .start();
  }

  leadShowTutorial() {
    introJs()
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: true,
        doneLabel: "JE PASSE À L'ACTION !",
        nextLabel: 'SUIVANT',
        prevLabel: 'Précédent',
        steps: [
          {
            title: "Écran d'un Lead",
            intro:
              "C'est ici que tu vas pouvoir appeler tes leads et passer à la suite du processus, appelé <b>qualification</b>.",
          },
          {
            element: this.callTarget,
            intro:
              "C'est le bouton d'appel, tu peux cliquer dessus pour <b>appeler ton lead.</b> Si tu es sur ordinateur, un numéro à appeler va s'afficher.",
          },
        ],
      })
      .start();
  }

  leadEditTutorial() {
    const alerts = document.querySelector('.dropdownBellMobile-js');
    alerts.classList.add('hidden');
    introJs()
      .oncomplete(() => {
        alerts.classList.remove('hidden');
      })
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: true,
        doneLabel: 'JE QUALIFIE !',
        nextLabel: 'SUIVANT',
        prevLabel: 'Précédent',
        steps: [
          {
            title: 'Écran de qualification',
            intro:
              "C'est ici que tu vas pouvoir qualifier tes leads. <b>Remplis les champs ci-dessous</b> en fonction des informations que tu obtiens par téléphone.",
          },
          {
            element: this.statusTarget,
            intro:
              "C'est avec ce champ que tu peux indiquer la sortie pour ton lead, ici <b>Valider l'onboarding</b>",
          },
          {
            element: this.qualifyTarget,
            intro: 'Quand tout est fini, tu peux appuyer sur <b>QUALIFIÉ</b>',
          },
        ],
      })
      .start();
  }

  toggleSideBars(stepNumber) {
    const headerSide = document.querySelector('.header__side');
    const alerts = document.querySelector('.dropdownBellMobile-js');
    const introJsDiv = document.querySelector('.introjs-tooltipReferenceLayer');
    const introJsHighlightDiv = document.querySelector('.introjs-helperLayer');
    switch (stepNumber) {
      case 1:
        headerSide.classList.add('header__side--active');
        introJsDiv.style.left = '0.5rem';
        introJsHighlightDiv.style.left = '0.5rem';
        break;
      case 4:
        headerSide.classList.add('header__side--active');
        introJsDiv.style.left = '0.5rem';
        introJsHighlightDiv.style.left = '0.5rem';
        break;
      case 5:
        headerSide.classList.remove('header__side--active');
        alerts.classList.add('hidden');
        break;
      default:
        alerts.classList.remove('hidden');
        headerSide.classList.remove('header__side--active');
        break;
    }
  }
}
