import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    new ClipboardJS(".copy");
  }

  message() {
    alert("Cela a bien été copié dans ton presse papier !");
  }

  copyToClip() {
    let str = document.getElementById("copy-text").textContent;
    function listener(e) {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
    this.message();
  }
}
