// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("channels");
require("./service-worker/addServiceWorker");
require("./nested-forms/addFields");
require("./nested-forms/removeFields");
require("chartkick");
require("chart.js");
require ('chart.js/auto');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import "emoji-picker-element";
import Chart from 'chart.js/auto';
import "chartkick/chart.js"
import Rails from "@rails/ujs";
import * as Turbo from "@hotwired/turbo-rails";
import "@rails/request.js";

global.Chart = Chart;