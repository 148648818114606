import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['button', 'radioButton'];

  connect() {
    if (window.location.href.includes('call_me') && document.querySelector('input[type="radio"]')  != null) {
      this.buttonTarget.disabled = true;
      this.buttonTarget.classList.add('button--disabled');
    }
  }

  submit(e) {
    e.preventDefault();
    const { url, method } = this.buttonTarget.dataset;
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add('button--disabled');
    Rails.ajax({
      url,
      type: method,
      success: (response, status, _) => {
        location.reload();
      },
      error: (response, status, _) => {
        this.buttonTarget.disabled = false;
        this.buttonTarget.classList.remove('button--disabled');
      },
    });
  }

  enableDisableButton(e) {
    const radioButton = e.currentTarget.querySelector('input[type="radio"]');

    if (radioButton.checked) {
      this.buttonTarget.disabled = false;
      this.buttonTarget.classList.remove('button--disabled');
    } else {
      this.buttonTarget.disabled = true;
      this.buttonTarget.classList.add('button--disabled');
    }
  }
}
