import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    
    let dropdownVideoBtns = document.querySelectorAll(".overlay");

    dropdownVideoBtns.forEach(videoBtn => videoBtn.addEventListener("click", (e) => this.showVideo(e)));    

    let videos = document.querySelectorAll("#static_video_url")
    videos.forEach(video => video.style.display = "none");
  }

  showVideo(e) {

    let viewport_width = window.innerWidth;
    let parent = e.target.parentElement
    let video = parent.getElementsByTagName('turbo-frame')[0]
    
    video.style.display === "none" ? video.style.display = "block" : video.style.display = "none"

    parent.style.minHeight === 'auto' ? parent.style.minHeight = '20px' : parent.style.minHeight = 'auto'

  }

}
