import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["messageList"];

  
  connect() {
    const messageList = this.messageListTarget;
    messageList.scrollIntoView({ behavior: 'smooth', block: 'end' });
    const options = {
      childList: true
    }
    const callback = function (mutationsList) { 
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          messageList.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(messageList, options);
  }
}
