import { Controller } from 'stimulus';
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/src/tagify.scss';
import '@yaireo/dragsort/dist/dragsort.css';

const DragSort = (function (t) {
  var e,
    i = 0,
    s = {},
    r = {},
    a =
      ((e = window.MutationObserver || window.WebKitMutationObserver),
      function (t, i) {
        t &&
          1 === t.nodeType &&
          (e
            ? new e(function (t, e) {
                i(t);
              }).observe(t, { childList: !0, subtree: !1 })
            : window.addEventListener &&
              t.addEventListener('DOMNodeInserted', i, !1));
      });
  function n(t, e) {
    if (!t) return this;
    (e = e || {}),
      (this.parentElm = t),
      (this.uid = e.uid),
      (this.settings = { selector: '*', callbacks: {} }),
      Object.assign(this.settings, e),
      this.setup(),
      a(this.parentElm, this.setup.bind(this)),
      this.bindEvents();
  }
  return (
    (n.prototype = {
      namespace: 'dragsort',
      setup() {
        [...this.parentElm.childNodes].forEach((t) => {
          if (1 != t.nodeType) return t.parentNode.removeChild(t);
          t.matches(this.settings.selector) && (t.draggable = !0);
        }),
          (this.gap = this.getItemsGap(this.parentElm.firstElementChild));
      },
      throttle(t, e) {
        var i = !1,
          s = this;
        return function (r) {
          i || (t.call(s, r), (i = !0), setTimeout(() => (i = !1), e));
        };
      },
      getDraggableElm(t) {
        var e = t.closest('[draggable="true"]');
        return this.uid == s.uid ? e : null;
      },
      dragstart(t, e) {
        s = this;
        var i,
          r = this.getDraggableElm(e);
        r
          ? ((this.source = this.getInitialState()),
            (this.target = this.getInitialState()),
            (i = r.getBoundingClientRect()),
            (this.source.elm = r),
            (this.source.idx = this.getNodeIndex(r)),
            (this.source.size.width = i.width),
            (this.source.size.height = i.height),
            (t.dataTransfer.effectAllowed = 'move'),
            setTimeout(this.afterDragStart.bind(this)))
          : (s = {});
      },
      afterDragStart() {
        var t = 'vertical' == this.settings.mode ? 'height' : 'width';
        this.parentElm.classList.add(this.namespace + '--dragStart'),
          (this.source.elm.style[t] = this.source.size[t] + 'px'),
          this.source.elm.classList.add(this.namespace + '--dragElem');
      },
      dragover(t) {
        t.preventDefault(), t.stopPropagation();
        var e = t.target;
        if ((e = this.getDraggableElm(e)) && this.target) {
          var i = this.target.elm,
            s = this.target.hoverDirection;
          (t.dataTransfer.dropEffect = 'move'),
            (this.target.hoverDirection = this.getTargetDirection(t)),
            (i == e && s == this.target.hoverDirection) ||
              this.directionAwareDragEnter(t, e);
        }
      },
      dragenter(t, e) {
        (e = this.getDraggableElm(e)) &&
          this.target &&
          this.isValidElm(e) &&
          this.source.elm != e &&
          this.source.elm &&
          (this.target.bounding = e.getBoundingClientRect());
      },
      directionAwareDragEnter(t, e) {
        var i;
        t.preventDefault(),
          t.stopPropagation(),
          (t.dataTransfer.dropEffect = 'none'),
          this.isValidElm(e) &&
            this.source.elm != e &&
            this.source.elm &&
            ((t.dataTransfer.dropEffect = 'move'),
            this.cleanupLastTarget(),
            (this.target.elm = e),
            (this.target.idx = this.getNodeIndex(e)),
            e.classList.add('over'),
            (i = Math.abs(this.target.idx - this.source.idx)),
            this.source.elm.classList.toggle(this.namespace + '--hide', i > 0),
            'vertical' == this.settings.mode
              ? (this.target.elm.style[
                  this.target.hoverDirection ? 'marginBottom' : 'marginTop'
                ] = this.source.size.height + this.gap + 'px')
              : (this.target.elm.style[
                  this.target.hoverDirection ? 'marginRight' : 'marginLeft'
                ] = this.source.size.width + this.gap + 'px'));
      },
      dragend(t) {
        if (
          (clearTimeout(this.dragoverTimeout),
          (this.dragoverTimeout = null),
          this.parentElm.classList.remove(this.namespace + '--dragStart'),
          !this.isValidElm(this.target.elm))
        )
          return this.cleanup();
        var e = this.target.hoverDirection
          ? this.target.elm.nextElementSibling
          : this.target.elm;
        return (
          this.source.elm != this.target.elm &&
            this.target.elm &&
            (this.target.elm.classList.add(this.namespace + '--noAnim'),
            this.cleanup(),
            this.parentElm.insertBefore(this.source.elm, e)),
          this.source.elm &&
            this.source.elm.classList.remove(
              this.namespace + '--dragElem',
              this.namespace + '--hide'
            ),
          this.settings.callbacks.dragEnd(this.source.elm),
          this
        );
      },
      isTargetLastChild() {
        return this.parentElm.lastElementChild == this.target.elm;
      },
      getTargetDirection(t) {
        if (this.target.bounding)
          return 'vertical' == this.settings.mode
            ? t.pageY >
              this.target.bounding.top + this.target.bounding.height / 2
              ? 1
              : 0
            : t.pageX >
              this.target.bounding.left + this.target.bounding.width / 2
            ? 1
            : 0;
      },
      getNodeIndex(t) {
        for (var e = 0; (t = t.previousSibling); )
          (3 == t.nodeType && /^\s*$/.test(t.data)) || e++;
        return e;
      },
      isValidElm(t) {
        return t && t.nodeType && t.parentNode == this.parentElm;
      },
      cleanup() {
        (s = {}),
          [...this.parentElm.children].forEach((t) => {
            t.removeAttribute('style'),
              setTimeout(() => {
                t.classList.remove(
                  this.namespace + '--over',
                  this.namespace + '--noAnim',
                  this.namespace + '--dragElem'
                );
              }, 50);
          });
      },
      cleanupLastTarget() {
        this.target.elm &&
          (this.target.elm.classList.remove(
            this.namespace + '--hide',
            this.namespace + '--over'
          ),
          this.target.elm.removeAttribute('style'));
      },
      getInitialState: () => ({ elm: null, size: {} }),
      getItemsGap(t) {
        var e = getComputedStyle(t);
        return 'vertical' == this.settings.mode
          ? parseInt(e.marginTop) + parseInt(e.marginBottom)
          : parseInt(e.marginLeft) + parseInt(e.marginRight);
      },
      bindEvents(t) {
        for (var e in ((this.listeners = this.listeners || {
          dragstart: (t) => this.dragstart(t, t.target),
          dragenter: (t) => this.dragenter(t, t.target),
          dragend: (t) => this.dragend(t, t.target),
          dragover: this.throttle(this.dragover, 350),
        }),
        this.listeners))
          this.parentElm[t ? 'removeEventListener' : 'addEventListener'](
            e,
            this.listeners[e]
          );
      },
      destroy() {
        this.cleanup(), this.bindEvents(!0), delete r[this.uid];
      },
    }),
    function (t, e) {
      return (
        (r[++i] = t._DragSort ? r[t._DragSort] : new n(t, { ...e, uid: i })),
        (t._DragSort = i),
        r[i]
      );
    }
  );
})();

export default class extends Controller {
  connect() {
    // The DOM element you wish to replace with Tagify
    const inputs = document.querySelectorAll('input.tags');

    const optionsTags = this.optionsTags;
    const normalTags = this.normalTags;
    inputs.forEach(function (input) {
      if (input.id.includes('tags_input_options')) {
        optionsTags(input);
      } else {
        normalTags(input);
      }
    });
  }

  refresh(e) {
    const inputs = document.querySelectorAll('input.tags');
    inputs.forEach(function (input) {
      const tagify = new Tagify(input, {
        originalInputValueFormat: (valuesArr) =>
          valuesArr
            .map((item) => {
              return item.value;
            })
            .join(','),
      });
    });
  }

  normalTags(input) {
    const tagify = new Tagify(input, {
      originalInputValueFormat: (valuesArr) =>
        valuesArr
          .map((item) => {
            return item.value;
          })
          .join(','),
    });
    if (tagify.DOM) {
      new DragSort(tagify.DOM.scope, {
        selector: '.' + tagify.settings.classNames.tag,
        callbacks: {
          dragEnd: (elm) => tagify.updateValueByDOMTags(),
        },
      });
    }
  }

  optionsTags(input) {
    const tagify = new Tagify(input, {
      whitelist: JSON.parse({ ...input.dataset }.options),
      dropdown: {
        classname: 'color-blue',
        enabled: 0, // show the dropdown immediately on focus
        maxItems: 30,
        position: 'text', // place the dropdown near the typed text
        closeOnSelect: false, // keep the dropdown open after selecting a suggestion
        highlightFirst: true,
      },
      enforceWhitelist: true,
      originalInputValueFormat: (valuesArr) =>
        valuesArr
          .map((item) => {
            return item.value;
          })
          .join(','),
    });
    if (tagify.DOM) {
      new DragSort(tagify.DOM.scope, {
        selector: '.' + tagify.settings.classNames.tag,
        callbacks: {
          dragEnd: (elm) => tagify.updateValueByDOMTags(),
        },
      });
    }
  }
}
