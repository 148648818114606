import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["button", "processing"];

  connect() {
    this.buttonTarget.disabled = true;
  }
  checkForDisable({target}) {
    const value = target.value;
    this.buttonTarget.disabled = value.trim().length <= 20;
  }
  answerNo(e) {
    if (e.target.classList.contains('text') ) {
      this.processingTarget.classList.remove("hidden")
      e.target.parentElement.classList.add("button--disabled");
    }
  }
}
