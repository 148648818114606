import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['fileInput', 'button'];

  connect() {
    this.id = this.fileInputTarget.dataset.id;
  }

  openFileInput(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  upload(_) {
    const data = new FormData();
    const uploadDiv = document.querySelector('.lead-card__upload-block');
    data.append('document', this.fileInputTarget.files[0]);
    Rails.ajax({
      url: `/user_document`,
      type: 'POST',
      data: data,
      success: (response, status, _) => {
        uploadDiv.classList.remove('lead-card__upload-block--failure');
        uploadDiv.classList.add('lead-card__upload-block--success');
        setTimeout(() => {
          uploadDiv.parentElement.parentElement.classList.add('hidden');
        }, 3000);
      },
      error: (response, status, _) => {
        uploadDiv.classList.remove('lead-card__upload-block--success');
        uploadDiv.classList.add('lead-card__upload-block--failure');
      },
    });
  }
}
