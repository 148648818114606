import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    this.checkboxTarget.addEventListener("change", this.update.bind(this));
  }


  update() {
    if (this.checkboxTarget.checked) {
      this.element.submit();
    }
  }
}
