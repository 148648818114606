import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "input", "submitButton"];

  connect() {
    if (window.location.pathname === "/chats") {
      const form = this.formTarget;
      // form.method = "get";
      const input = this.inputTarget;
      const submitButton = this.submitButtonTarget;

      input.onkeydown = (key) => {
        if (key.keyCode == 13 && !key.shiftKey) {
          submitButton.click();
        }
      };
    }
  }

  change(event) {
    const changedInput = event.target;
    const submitButton =
      changedInput.parentElement.parentElement.lastElementChild;

    submitButton.click();
  }
}
