import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "selected",
        "options",
    ];

    toggle() {
        document.getElementById('selecter').parentNode.classList.toggle(
            "custom-select--qualify-lead-active"
        );
        this.optionsTarget.classList.toggle("custom-select__options--hidden");
    }

    select({ target: option }) {
        document.getElementById('select-value').innerHTML = option.innerHTML;
        this.optionsTarget.classList.toggle("custom-select__options--hidden");
    }
}
