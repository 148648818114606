import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { subscribe: Boolean, subscription: String };

  initialize() {
    window.this_controller = this;
  }

  connect() {
    if (Notification.permission === 'granted') {
      this.sendSubscriptionToServer();
    }
  }

  managePermission() {
    if (!('Notification' in window)) {
      alert('Ce navigateur ne supporte pas les notifications');
      return;
    }
    if (Notification.permission === 'granted') {
      alert('Les notifications sont activées');
      return;
    }
    if (Notification.permission === 'denied') {
      alert('Les notifications sont désactivées');
      return;
    }
    if (Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          alert('Tu as accepté de recevoir des notifications');
          window.this_controller.sendSubscriptionToServer();
          return;
        }
        if (permission === 'denied') {
          alert('Tu as refusé de recevoir des notifications');
          window.this_controller.unSubscribeUser();
        }
      });
    }
  }

  unSubscribeUser() {
    navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager.getSubscription().then((subscription) => {
        if (subscription) {
          subscription
            .unsubscribe()
            .then((data) => {})
            .catch((error) => {
              console.log(error);
            });
        }
        Rails.ajax({ type: 'get', url: '/unsubscriptions' });
      });
    });
  }

  sendSubscriptionToServer() {
    const user_subscription = this.data.get('subscription');
    navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: window.vapidPublicKey,
        })
        .then((subscription) => {
          if (subscription) {
            const myData = {
              endpoint: subscription.toJSON().endpoint,
              auth: subscription.toJSON().keys.auth,
              p256dh: subscription.toJSON().keys.p256dh,
            };
            if (myData.p256dh !== user_subscription) {
              Rails.ajax({
                type: 'post',
                url: '/subscriptions',
                data: new URLSearchParams(myData).toString(),
              });
              this.data.set('subscription', myData.p256dh);
            }
          }
        });
    });
  }
}
