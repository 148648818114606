import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];
  static values = {};
  smsRegex =
    /[^!#$%'(){}*+,\-.:;'<=>?@_¡£¥€/¿&¤0-9A-Za-zÄÅÆÇÉÑØøÜßÖàäåæèéìñòöùüΔΦΓΛΩΠΨΣΘΞ\s]/gu;

  validateSms({ target }) {
    const invalidCharacters = target.value.match(this.smsRegex);
    const smsParagraphElement =
      document.getElementsByClassName('sms-errors')[0];
    this.displayErrorMessage(
      invalidCharacters,
      smsParagraphElement,
      'sur le dernier SMS tapé'
    );
  }

  displayErrorMessage(errors, htmlElement, contextString) {
    if (errors) {
      htmlElement.innerHTML = `Caractères invalides : ${errors.join(',')}`;
      htmlElement.classList.add('red');
    } else {
      htmlElement.innerHTML = `Aucune erreur ${contextString}`;
      htmlElement.classList.remove('red');
    }
  }
}
