var isSafari =
  window["safari"] &&
  safari.pushNotification &&
  safari.pushNotification.toString() === "[object SafariRemoteNotification]";

if (navigator.serviceWorker && !isSafari) {
  console.log("Service Worker is supported");
  navigator.serviceWorker.register("/serviceworker.js").catch(function(error) { console.log(error) })
}
// registration.pushManager.subscribe({
//     userVisibleOnly: true,
//     applicationServerKey: window.vapidPublicKey
// }).then(function (subscription) {
//     console.log("addServiceWorker:", subscription)
// })
