import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['quizContainer', 'radioButton'];

  showHideQuiz(event) {
    const radioButton = event.currentTarget.querySelector(
      'input[type="radio"]'
    );
    const quizContainer = this.quizContainerTarget;

    if (radioButton.checked) {
      quizContainer.classList.remove('card-item__quiz-container--hidden');
    } else {
      quizContainer.classList.add('card-item__quiz-container--hidden');
    }
  }
}
