import {Controller} from "stimulus"

export default class extends Controller {

  connect() {
    const row = this.element;
    this.toogleLink(row);
  }

  toogleLink(row) {
    row.addEventListener("click", () => {
      window.location = row.dataset.href;
    });
  }
}
