import {Controller} from "stimulus"


export default class extends Controller {
  static targets = ["input", "targetStatus"]

  show(event) {
    if (event.target.value === "hide") {
      this.targetStatusTargets.forEach((target) => {
        target.classList.add('hidden')
      })
    } else {
      this.targetStatusTargets.forEach((target) => {
        target.classList.remove('hidden')
      })
    }
  }
}

