import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'filter',
    'product',
    'formation',
    'selection',
    'chatNames',
    'chatSearch',
    'searchInput',
    'list',
  ];
  initialize() {
    this.filters = [];

    this.isProductPage = this.hasProductTarget;
    this.selected = [];
    this.filter();
  }

  cleanFilters() {
    let cleanFilters = {};
    const uglyFilters = this.filterTargets;

    uglyFilters.forEach((filter) => {
      cleanFilters[filter.dataset.fieldId] = filter.value;
    });

    return cleanFilters;
  }

  clean(individualFilter) {
    return individualFilter.toLowerCase().replace(/\s+/g, '');
  }

  toggleActive(product, filters) {
    product.classList.remove('hidden');
    let productFilters = {};
    const dataset = JSON.parse(product.dataset.productFilters);
    dataset.forEach((dataPoint) => {
      productFilters[dataPoint.field_id] = dataPoint.values.split(',');
    });
    let active = true;
    for (const [filter, value] of Object.entries(filters)) {
      if (!value) {
        active = true;
      } else if (productFilters[filter] === undefined) {
        active = true;
      } else if (
        productFilters[filter]
          .map((individualFilter) => this.clean(individualFilter))
          .includes(this.clean(value))
      ) {
        active = true;
      } else {
        active = false;
        break;
      }
    }
    if (active) {
      product.classList.remove('hidden');
    } else {
      product.classList.add('hidden');
    }
  }

  filter() {
    let products = this.productTargets;
    let filters = this.cleanFilters();
    products.forEach((product) => {
      this.toggleActive(product, filters);
    });
  }

  toggle({ target: currentTarget }) {
    const ticLeft = document.querySelector(
      `[data-tic-left='${currentTarget.dataset.inputIndex}']`
    );
    const ticRight = document.querySelector(
      `[data-tic-right='${currentTarget.dataset.inputIndex}']`
    );
    // const box = this.formationTarget;

    const { index, includeValue, id } = this.getValueFromTarget(currentTarget);

    if (currentTarget.checked && !includeValue) {
      ticLeft.classList.add('checkbox__tic-left--visible');
      ticRight.classList.add('checkbox__tic-right--visible');
      // box.classList.add('lead-box--selected');
      this.create_input(id);
      this.selected.push(id);
    } else if (!currentTarget.checked && includeValue) {
      ticLeft.classList.remove('checkbox__tic-left--visible');
      ticRight.classList.remove('checkbox__tic-right--visible');
      // box.classList.remove('lead-box--selected');
      this.selected.splice(index, 1);
      let element = document.getElementsByClassName(id)[0];
      element.remove();
    }
  }

  getValueFromTarget(currentTarget) {
    const extractedValue = this.isProductPage ? 'productId' : 'formationId';
    return {
      index: eval(
        `this.selected.indexOf(currentTarget.parentNode.dataset.${extractedValue})`
      ),
      includeValue: eval(
        `this.selected.includes(currentTarget.parentNode.dataset.${extractedValue})`
      ),
      id: eval(`currentTarget.parentNode.dataset.${extractedValue}`),
    };
  }

  create_input(item) {
    let input = document.createElement('input');
    input.type = 'integer';
    input.className = `integer optional hidden ${item}`;
    console.log(this.isProductPage);
    const inputType = this.isProductPage
      ? 'product_ids'
      : 'formation_shift_ids';
    input.name = `leads_normal[${inputType}][]`;
    input.value = item;
    document.getElementById('input').appendChild(input);
  }

  searchChats() {
    const chatNames = this.chatNamesTargets;
    let filtered = chatNames.map((chatName) => chatName.innerHTML);
    const chatSearch = this.chatSearchTarget;
    filtered = filtered.filter((value) =>
      value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(chatSearch.value.toLowerCase())
    );
    chatNames.forEach((element) => {
      if (filtered.includes(element.innerHTML)) {
        element.parentNode.classList.remove('chats-list__card--hidden');
      } else {
        element.parentNode.classList.add('chats-list__card--hidden');
      }
    });
  }

  search(filtered, list) {
    const searchInput = this.searchInputTarget;
    filtered = filtered.filter((value) =>
      value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(searchInput.value.toLowerCase())
    );
    list.forEach((element) => {
      if (filtered.includes(element.innerHTML)) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });
  }

  connect() {
    if (window.location.pathname === '/chats') {
      this.chatSearchTarget.addEventListener('keyup', (e) => {
        this.searchChats();
      });
    }

    if (window.location.pathname === '/users/mission_informations') {
      const list = this.listTargets;
      let filtered = list.map((element) => element.innerHTML);
      this.searchInputTarget.addEventListener('keydown', (e) => {
        this.search(filtered, list);
      });
    }

    $('.lead-info-select').on('select2:select ', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $('.user-tags-select').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }
}
