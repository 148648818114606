import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input", "verificationFrame", "stateText"];
  inProgress = false;

  connect() {
    this.changeStateText("verificate");
    this.inputTarget.addEventListener("click", (e) =>
      this.resetUnvalidInput(e.target)
    );
  }

  launchVerification(_event ) {
    if (this.inProgress) return;
    if (!this.checkState()) return;
    this.inProgress = true;

    const { lead_id } = this.verificationFrameTarget.dataset;
    this.switchState("verificate", "verifing");
    Rails.ajax({
      url: `/leads/${lead_id}/verificate_email?email=${this.inputTarget.value}`,
      type: "post",
      success: (response, status, _) => {
        this.switchState("verifing", "valid");
      },
      error: (response, status, _) => {
        this.switchState("verifing", "unvalid");
        setTimeout(() => {
          this.switchState("unvalid", "verificate");
        }, 5000);
      },
      complete: () => {
        this.inProgress = false;
      },
    });
  }

  switchState(old_state, new_state) {
    this.changeStateFrame(new_state, old_state);
    this.changeStateText(new_state);
    if (new_state == "unvalid") this.animateUnvalidInput();
  }

  animateUnvalidInput() {
    this.inputTarget.classList.add("animated");
  }

  checkState() {
    return this.verificationFrameTarget.classList.contains(
      "email-validator__verificate"
    );
  }

  resetUnvalidInput(target) {
    if (!target.classList.contains("animated")) return;
    target.classList.remove("animated");
  }

  changeStateFrame(newState, oldState) {
    this.removeState(oldState);
    this.addState(newState);
  }

  changeStateText(newState) {
    let stateWordings = JSON.parse(this.stateTextTarget.dataset.wording);
    this.stateTextTarget.textContent = stateWordings[newState];
  }

  removeState(oldState) {
    this.verificationFrameTarget.classList.remove(
      `email-validator__${oldState}`
    );
  }

  addState(newState) {
    this.verificationFrameTarget.classList.add(`email-validator__${newState}`);
  }
}
