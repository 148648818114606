import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sms", "total"];

  connect() {
    console.log(this.estimateCost);
    this.estimateCost();
    this.smsTarget.addEventListener("input",()=>this.estimateCost())
  }

  estimateCost() {
    const costPerSlice = this.totalTarget.dataset.smsPricing || 0.028;
    const newLinesCount = this.countNewlines(this.smsTarget.value);
    const newLinewCaracteres = newLinesCount * 2; // un saut de ligne gènere 2 caractères quand vonage l'envoi
    const smsLength = this.smsTarget.value.length;
    const totalCaracters = newLinewCaracteres + smsLength;
    const smsSlices = Math.ceil(totalCaracters / 145);
    const totalCost = smsSlices * costPerSlice;
    this.totalTarget.textContent = `${smsSlices} SMS envoyés au lead | Coût de l'envoi: ${totalCost}€`;
  }

  countNewlines(str) {
    const regex = /\n/g;
    const matches = str.match(regex);
    return matches ? matches.length : 0;
  }
}