import {Controller} from "stimulus"


export default class extends Controller {
  hideModal(event) {
    $('#redirectLeadModal').modal('hide')

  }


}
