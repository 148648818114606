import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["errorMessage"];

  next() {
    const reminder = document.getElementById("reminder");
    reminder.classList.remove("hidden");
  }

  noNewLead() {
    this.errorMessageTarget.classList.remove("button__error-label--hidden");
  }
}
