import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];
  connect() {
    const smsReminderModal = document.getElementById('reminderSmsModal');
    if (smsReminderModal) {
      smsReminderModal.classList.remove('modal--hidden');
    }
  }

  displayModal(e) {
    if (window.location.pathname.includes(['/users/edit', '/users/archives'])) {
      e.preventDefault();
    }
    this.modalTarget.classList.remove('modal--hidden');
  }

  displayModalById(event) {
    event.preventDefault();
    const modalId = event.target.dataset.modalDisplay;
    const modal = this.modalTargets.find(
      (modalTarget) => modalTarget.id === modalId
    );
    
    if (modal) {
      modal.classList.remove('modal--hidden');
    } else {
      console.error(`No modal found with id ${modalId}`);
    }
  }
  

  displayTooltipModal(event) {
    event.preventDefault();
    const modalId = event.target.dataset.modalDisplay;
    const modal = this.modalTargets.find(
      (modalTarget) => modalTarget.id === modalId
    );
    const elements = modal.getElementsByTagName('p');
    elements[0].innerHTML = event.target.dataset.label;
    elements[1].innerHTML = event.target.dataset.tooltip;
    this.displayModalById(event);
  }

  close(e) {
    this.modalTargets.forEach((modalTarget) => {
      if (!modalTarget.classList.value.includes('modal--hidden')) {
        modalTarget.classList.add('modal--hidden');
      }
    });
  }
}
