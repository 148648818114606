import { Controller } from "stimulus";

// Connects to data-controller="ai-chat-message-form"
export default class extends Controller {
  static targets = ['icon'];

  changeColor(event) {
    event.preventDefault();
    const icon = event.target
    if (icon.src.includes('dislike-red')) {
      icon.src = '../../assets/dislike.svg'
    } else {
      icon.src = '../../assets/dislike-red.svg'
    }
  }
}
