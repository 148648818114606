import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["eye"];

  toggleVisibility() {
    const target = this.eyeTarget;

    if (target.dataset.state === "obscured") {
      this.makeVisible(target);
    } else {
      this.obscure(target);
    }
  }

  makeVisible(target) {
    const input = target.parentElement.querySelector(".password-input-js");
    console.log(target.parentElement);
    const eyeIcon = target.querySelector(".password-icon-js");
    target.dataset.state = "visible";
    input.type = "text";
    eyeIcon.classList.remove("fa-eye", "show__icon--blue");
    eyeIcon.classList.add("fa-eye-slash", "show__icon--gray");
  }

  obscure(target) {
    const input = target.parentElement.querySelector(".password-input-js");
    const eyeIcon = target.querySelector(".password-icon-js");
    target.dataset.state = "obscured";
    input.type = "password";
    eyeIcon.classList.add("fa-eye", "show__icon--blue");
    eyeIcon.classList.remove("fa-eye-slash", "show__icon--gray");
  }
}
