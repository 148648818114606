import { Controller } from 'stimulus';

export default class extends Controller {

  toggleRecordings(event) {
    const parent = event.target.parentNode;
    const input = parent.querySelector('input');

    if (input.checked) {
      const audios = Array.from(parent.querySelectorAll('audio'));
      audios.forEach((audio) => {
        if ( audio.src === '') this.setAudioSrc(audio);
      });
    }
  }

  setAudioSrc(audio) {
    const audioId = audio.dataset.callId;
    audio.src = `/calls/${audioId}/download_recording`;
    audio.load();
  }
}
