import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["lead", "link"];
  async link(event) {
    let clicked = event.target.dataset.clicked
    if (clicked != 'true') {
      event.target.dataset.clicked = 'true'
      this.callUrl(event).then(() => {
        if (event.target.dataset.link != undefined) {
          window.location.href = event.target.dataset.link
        }
        setTimeout(() => window.location.href = '/', 500)
      })
    }
  }

  async callUrl(event) {
    console.log('in the call url')
    await fetch(
      `/leads/${event.target.dataset.id}/next_step_process`, 
      {
        method: "post",
      }
    ).then(() => {
      return () => {}
    }).catch((err) => {
      console.log(err)
    })
    
  }
}
