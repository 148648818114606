import {Controller} from "stimulus"

import {DataTable} from "simple-datatables"

export default class extends Controller {
  connect () {

    new DataTable(".datatable", {
      searchable: false,
      paging: false,
      footer: false,
    })

  }
}
