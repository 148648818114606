import {Controller} from "stimulus"

import Rails from '@rails/ujs';

export default class extends Controller {

  disconnect() {
    Rails.ajax({
      type: "put",
      url: `/leads/set_leads_showing_to_false`,
    })
  }
}
