import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['sendButton'];

  sendMessage() {
    const sendButton = this.sendButtonTarget;
    const data = sendButton.dataset;
    const domRing = '<div class="loading-ring" id="loading-ring"></div>';
    const domImage = `<img class="icon--sized-big icon--fade" src="${data.icon}"><p class="text text--bold text--colored-main-blue">{text}</p>`;
    const domFailImage = `<img class="icon--sized-big icon--fade" id="fail-image" src="${data.failIcon}"><p class="text text--bold text--colored-main-blue" id="fail-text">Une erreur est survenue. Pas de panique 😉</p>`;
    const reminder = data.reminder;

    this.insertHtml(sendButton, 'beforebegin', domRing);
    sendButton.remove();

    Rails.ajax({
      url: `/send_first_message?lead_number=${data.leadNumber}&mission_number=${
        data.missionNumber
      }&text=${encodeURIComponent(data.text)}&lead_id=${data.leadId}&user_id=${
        data.userId
      }`,
      type: 'POST',
      success: (response, status, _xhr) => {
        const loadingRing = document.getElementById('loading-ring');

        setTimeout(() => {
          this.insertHtml(loadingRing, 'beforebegin', domImage.replace('{text}', response.message));
          loadingRing.remove();
        }, 1000);

        setTimeout(() => {
          window.location.replace('/users/portfolio');
        }, 3500);
      },
      error: (response, status, _xhr) => {
        const loadingRing = document.getElementById('loading-ring');

        setTimeout(() => {
          this.insertHtml(loadingRing, 'beforebegin', domFailImage);
          loadingRing.remove();
          const failImage = document.getElementById('fail-image');
          const failText = document.getElementById('fail-text');

          if (reminder === 'true') {
            setTimeout(() => {
              document
                .getElementsByClassName('lead-card__message-action')[0]
                .appendChild(sendButton);
              failImage.remove();
              failText.remove();
            }, 4000);
          }
        }, 1000);
      },
    });
  }

  insertHtml = (target, position, domItem) => {
    target.insertAdjacentHTML(position, domItem);
  };
}
