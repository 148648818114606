import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'bellContentMobile',
    'bellContent',
    'content',
    'emojis',
    'chatActions',
    'chatActionsMobile',
    'filterImage',
    'filterCross',
  ];

  connect() {
    if (window.location.pathname === '/chats') {
      this.emojisTarget.addEventListener('emoji-click', (event) => {
        let input = document.getElementById('message_text');
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        input.value =
          input.value.substring(0, startPos) +
          event.detail.unicode +
          input.value.substring(endPos, input.value.length);
      });
    }
  }

  toggleDropdown() {
    if (this.hasNameBellContent) {
      this.bellContentTarget.className.includes('dropdown__content--hidden');
    }
    this.contentTarget.classList.toggle('dropdown__content--hidden');
  }

  toggleBellDropdown() {
    const content = this.contentTarget;
    const bellContent = this.bellContentTarget;

    bellContent.classList.toggle('dropdown__content--hidden');
    content.className.includes('dropdown__content--hidden')
      ? null
      : content.classList.toggle('dropdown__content--hidden');

    axios.patch('/users/read_alerts');
  }

  toggleMobileBellDropdown() {
    const bellContent = this.bellContentMobileTarget;

    bellContent.classList.toggle('dropdown__content--active');
    axios.patch('/users/read_alerts');
  }

  closeFromAnywhere(e) {
    if (document.querySelector('.dropdown-js')) {
      const content = document.querySelector('.dropdown-js');
      const bellContent = document.querySelector('.dropdownBell-js');
      if (
        e.target.className !== 'dropdown__content' &&
        e.target.className !== 'dropdown__title' &&
        !content.className.includes('dropdown__content--hidden')
      ) {
        content.classList.toggle('dropdown__content--hidden');
      } else if (
        e.target.className !== 'bell-dropdown__content' &&
        e.target.className !== 'header__bell' &&
        bellContent &&
        !bellContent.className.includes('dropdown__content--hidden')
      ) {
        bellContent.classList.toggle('dropdown__content--hidden');
      }
    }
  }

  toggleMenu(e) {
    const { target } = e;
    const menu = document.querySelector('.header__side');
    if (target.checked) {
      menu.classList.add('header__side--active');
    } else {
      menu.classList.remove('header__side--active');
    }
  }

  toggleFilter() {
    const image = this.filterImageTarget;
    const cross = this.filterCrossTarget;
    const menu = document.querySelector('.filter');
    menu.classList.toggle('filter--visible');
    image.classList.toggle('hidden');
    cross.classList.toggle('hidden');
  }

  toggleEmojis() {
    const emojisKeyboard = this.emojisTarget;
    emojisKeyboard.classList.toggle('dropdown__content--hidden');
    this.closeOnClick(emojisKeyboard);
  }

  toggleChatActions() {
    const chatActions = this.chatActionsTarget;
    chatActions.classList.toggle('dropdown__content--hidden');
    this.closeOnClick(chatActions);
  }

  toggleChatActionsMobile() {
    const chatActionsMobile = this.chatActionsMobileTarget;
    chatActionsMobile.classList.toggle('dropdown__content--hidden');
    this.closeOnClick(chatActionsMobile);
  }

  closeOnClick(element) {
    if (!element.classList.value.includes('dropdown__content--hidden')) {
      setTimeout(() => {
        document.addEventListener(
          'click',
          (e) => {
            if (e.target !== element) {
              element.classList.add('dropdown__content--hidden');
            }
          },
          { once: true }
        );
      }, 100);
    }
  }
}
