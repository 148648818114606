import {Controller} from "stimulus"
import $ from "jquery";

import Select2 from "select2"
import "select2/dist/css/select2.css";


export default class extends Controller {

    connect () {
        $(document).on('turbo:before-cache', function(e) {
          if ($('select').data('select2')) {
            $('select').select2('destroy');
          }

        });

        $(".lead-info-select").select2({
            "multiple": false,
            "tags": true,
            "tokenSeparators": [
                ",",
                " "
            ],
            "debug": true
        });


    }

}
