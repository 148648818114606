import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['element', 'selected']

  toggle() {

    const element = this.elementTarget
    const hiddenChild = element.closest('.toggle-container').querySelector(".hidden-element")
    hiddenChild.classList.toggle('hidden')
    if(element.style.borderColor === "green") {
      element.style.borderColor = "red"
    } else {
      element.style.borderColor = "green"
    }
  }
  
  selectAnOption() {
    const element = this.elementTarget
    const label = element.closest('label')
    label.innerText = this.selectedTarget.selectedOptions[0]?.innerText || "Le step n'a pas été fait"
    this.toggle()
  }
}
