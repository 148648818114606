import { Controller } from 'stimulus';
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = ['counter', 'counterDiv'];

  connect() {
    const counter = this.counterTarget;
    const counterDiv = this.counterDivTarget;
    const options = { decimalPlaces: 2, duration: 2 };
    const countUp = new CountUp(counter, counter.dataset.counter, options);
    countUp.start();
    countUp.target.addEventListener('DOMSubtreeModified', () => {
      if (parseInt(counter.dataset.counter) >= parseInt(counterDiv.dataset.objective)) {
        counterDiv.dataset.displayRight = 'false';
      } else if (parseInt(counterDiv.dataset.objectiveRatio, 10) >= 70) {
        counterDiv.dataset.displayRight = 'false';
        counterDiv.style.right = `${
          105 - parseInt(counterDiv.dataset.objectiveRatio, 10)
        }%`;
      } else {
        counterDiv.dataset.displayRight = 'true';
      }
    });
  }
}
