import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hiddenPlayer","timer","timeBar","controlPausePlay"];

  initialize(){
    this.controlPausePlayTarget.checked = true
    this.timeBarTarget.max = this.hiddenPlayerTarget.duration
    this.updateTimeBar()
  }

  updateTimeBar(){
    this.timeBarTarget.value = this.hiddenPlayerTarget.currentTime
    this.updateTimer()
  }

  updatePlayer(){
    this.hiddenPlayerTarget.currentTime = this.timeBarTarget.value
    this.updateTimer()
  }

  updateTimer(){
    const currentTimeJolie = this.secondsToMinutes(this.hiddenPlayerTarget.currentTime)
    this.timerTarget.innerHTML = currentTimeJolie
    if (this.hiddenPlayerTarget.duration == this.hiddenPlayerTarget.currentTime && !this.controlPausePlayTarget.checked) this.controlPausePlayTarget.click()
   }

  updateStatus(event){
    if (event.target.id == "pause-play") {
      if (event.target.checked) {
        this.hiddenPlayerTarget.pause()
      } else {
          this.hiddenPlayerTarget.play()
      }
  } else {
      this.hiddenPlayerTarget.currentTime = 0
      if (this.controlPausePlayTarget.checked) this.controlPausePlayTarget.click();
  }
  }

  secondsToMinutes(time) {
    let minutes = Math.floor(time / 60);
    let seconds = Math.round(time % 60);
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return minutes + ':' + seconds;
}
}
