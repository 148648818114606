import { Controller } from 'stimulus';
import { get } from '@rails/request.js';
export default class extends Controller {
  static values = {
    url: String,
    page: Number,
    scroll: Boolean,
  };

  initialize() {
    this.scroll = this.scroll.bind(this);
    this.pageValue = parseInt(this.data.get('pageValue')) || 1;
    this.modelName = this.data.get('modelName') || '';
    this.client = this.data.get('client') || '';
    this.mission = this.data.get('mission') || '';
    this.status = this.data.get('status') || '';
    this.caller = this.data.get('caller') || '';
    this.dates = this.data.get('dates') || '';
    this.leadsTotal = this.data.get('leadsTotal') || 0;
    this.leadsCount =
      document.getElementById('leads_count')?.dataset?.paginationLeadsCount ||
      0;
    this.renderElements = this.renderElements.bind(this);
    this.fillTarget = this.fillTarget.bind(this);
    this.elementDisplay = this.elementDisplay.bind(this);
    this.storeCountLeads = this.storeCountLeads.bind(this);
    this.leadsCounter = this.leadsCounter.bind(this);
    this.hasMoreQuotes = this.hasMoreQuotes.bind(this);
    this.isLoading = false;
    this.isActive = false;
  }

  connect() {
    document.addEventListener('scroll', this.scroll);
  }

  scroll() {
    if (
      !this.isActive &&
      this.scrollReachedEnd &&
      !this.hasLastPageTarget &&
      this.hasMoreQuotes(this.pageValue, 25, this.leadsTotal)
    ) {
      this.isActive = true;
      this._fetchNewPage();
    }
  }

  hasMoreQuotes(page, limit, total) {
    const startIndex = page * limit + 1;
    return total === 0 || startIndex < total;
  }

  get scrollReachedEnd() {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    return distanceFromBottom < 5;
  }

  async _fetchNewPage(e) {
    const url = new URL(this.data.get('urlValue'));
    this.pageValue += 1;

    url.searchParams.set('page', this.pageValue);
    url.searchParams.set('model_name', this.modelName);
    url.searchParams.set('client_id', this.client);
    url.searchParams.set('mission_id', this.mission);
    url.searchParams.set('user_id', this.caller);
    url.searchParams.set('status_id', this.status);
    url.searchParams.set('updated_at', this.dates);
    url.searchParams.set('origin_context', 'leads_search_index');

    console.log(url.searchParams.toString());

    await this.loader(url.toString(), this.fillTarget, this.element);
    this.isActive = false;
  }

  fillTarget(element, collection) {
    return element.insertAdjacentHTML(
      'beforeend',
      this.renderElements(collection)
    );
  }

  renderElements(elements) {
    const array = [];
    elements.forEach((element) => {
      array.push(this.elementDisplay(element));
    });
    return array.join('');
  }

  async loader(apiEndpoint, callbackFn, element) {
    const result = await fetch(apiEndpoint);
    if (!result.ok) {
      throw new Error(`An error occurred: ${result.status}`);
    }
    // at this point, we have a good result:
    const jsonObj = await result.json();
    // run our callback function, passing in that object
    callbackFn(element, jsonObj);
    this.hideSpanCount();
    let leadsCountResult = this.leadsCounter(jsonObj.length);
    if (this.leadsTotal - leadsCountResult !== 0) {
      this.storeCountLeads(element, leadsCountResult);
    }
  }

  hideSpanCount() {
    if (this.pageValue === 2) {
      const span = document.getElementById('leads_count');
      console.log(span);
      span.remove();
    } else {
      const span = document.getElementById(`leads_count-${this.pageValue - 1}`);
      span.remove();
    }
  }

  leadsCounter(pageLeadsSize) {
    return pageLeadsSize + parseInt(this.leadsCount);
  }

  storeCountLeads(element, leadsCountNumber) {
    const count = `<span class="hidden" id="leads_count-${this.pageValue}" data-pagination-leads-count="${leadsCountNumber}"></span>`;
    return element.insertAdjacentHTML('beforeend', count);
  }

  elementDisplay(element) {
    const textToAdd = `
      <tr>
        <td class="text-clients">
          <span>${element.first_name}</span>
          <span>${element.phone_number}</span>
        </td>
        <td class="text-clients">
          ${element.phone_number}
        </td>
        <td class="text-clients clients-table__mission">
          <p title="${element.mission}">
            ${element.mission}
          </p>
        </td>
        <td class="text-clients clients-table__status" data-status="${element.status_type}">${element.status}</td>
        <td class="text-clients">
          ${element.caller}
        </td>
      </tr>`;
    return textToAdd;
  }
}
