import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["barContainer", "progressBar"];

  connect() {
    const barContainerWidth = this.barContainerTarget.offsetWidth;
    const progressBar = this.progressBarTarget;

    new ResizeObserver((size) => {
      let width = progressBar.offsetWidth;
      this.widthComparator(barContainerWidth, width, progressBar);
    }).observe(progressBar);
  }

  widthComparator(totalWidth, progressWidth, progressBar) {
    const ratio = (progressWidth / totalWidth) * 100;
    switch (true) {
      case ratio <= 35:
        progressBar.style.backgroundColor = "rgba(255, 114, 119, 1)";
        break;
      case ratio > 35 && ratio <= 50:
        progressBar.style.backgroundColor = "rgba(248, 172, 58, 1)";
        break;
      case ratio > 50 && ratio <= 85:
        progressBar.style.backgroundColor = "rgba(255, 222, 50, 1)";
        break;
      case ratio > 85:
        progressBar.style.backgroundColor = "rgba(125, 226, 186, 1)";
        break;
      default:
        progressBar.style.backgroundColor = "blue`";
        break;
    }
  }
}
