import { Controller } from "stimulus";
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = ["studyLevel", "specialties"];

  connect() {
    const missionId = document.querySelector('[data-mission-id]').dataset.missionId;
    if (missionId !== '1692') return;
    const studyLevel = this.studyLevelTarget.innerText;
    const specialties = document.querySelector('[data-name]', 'Spécialités');
    this.mapSpecialties(studyLevel, specialties)
  }
  
  updateSpecialitesBlock(event) {
    if (event.target.dataset.missionId !== '1692') return;
    if (event.target.dataset.fieldName == 'Niveau actuel') {
      const studyLevel = this.studyLevelTarget.innerText;
      const specialties = document.querySelector('[data-name]', 'Spécialités');
      specialties.value = ''
      specialties.parentElement.getElementsByTagName('tags')[0].remove()
      this.mapSpecialties(studyLevel, specialties)
    }
  }
  
  mapSpecialties(studyLevel, specialties) {
    if (['Bac', 'Première', 'Terminale'].includes(studyLevel) ) {
      const tagify = new Tagify(specialties, {
        whitelist: ["Arts", "Biologie Écologie", "TMD", "Mathématiques", "S2TMD", "STL", "STD2A", "STAV", "Histoire-géographie géopolitique et Sciences politiques (HGGSP)", "Humanités Littérature et Philosophie (HLP)", "Langues Littératures et Cultures étrangères et régionales (LLCER)", "Littératures Langues et Cultures de l'antiquité (LLCA)", "STHR", "Physique-chimie (PC)", "Sciences de la vie et de la Terre (SVT)", "Sciences économiques et sociales (SES)", "Numérique et sciences informatique (NSI)", "Sciences de l'ingénieur (SI)", "Éducation physique, Pratiques Culture sportives (EPPCS)", "STMG", "ST2S", "STI2D", "PRO ASSP", "PRO mécanique", "PRO cuisine", "PRO commerce", "PRO vente prospect négociation", "PRO esthétique cosmétique", "musique", "PRO technicien installation clim", "PRO service personnes et territoires", "PRO laboratoire", "PRO tourisme", "PRO électronique", "PRO commerce", "PRO Art", "PRO hygiène - environnement", "PRO BTP", "PRO Transport", "PRO aéronautique", "PRO service à la personne", "PRO communication", "PRO design", "PRO multimédia", "PRO Restauration et l'hôtellerie", "PRO informatique", "PRO Logistique", "PRO agriculture", "PRO Maintenance", "PRO industrie", "PRO mode", "PRO métiers de l'accueil", "PRO gestion administration", "PRO optique", "PRO métiers de la sécurité", "AMC (anglais monde contemporain)", "PRO Animation Enfance et Personnes Agées (AEPA)"],
        maxTags: 10,
        dropdown: {
          maxItems: 50,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false
        },
        enforceWhitelist: true,
        originalInputValueFormat: (valuesArr) =>
          valuesArr
            .map((item) => {
              return item.value;
            })
            .join(','),
      })
    } else if (studyLevel === 'Seconde') {
      const tagify = new Tagify(specialties, {
        whitelist: ['Générale et Technologique', 'Professionnel (BEP)', 'CAP'],
        maxTags: 10,
        dropdown: {
          maxItems: 50,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false
        },
        enforceWhitelist: true,
        originalInputValueFormat: (valuesArr) =>
          valuesArr
            .map((item) => {
              return item.value;
            })
            .join(','),
      })
    } else if (studyLevel === 'Collège') {
      const tagify = new Tagify(specialties, {
        whitelist: ['Collège'],
        maxTags: 10,
        dropdown: {
          maxItems: 50,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false
        },
        enforceWhitelist: true,
        originalInputValueFormat: (valuesArr) =>
          valuesArr
            .map((item) => {
              return item.value;
            })
            .join(','),
      })
    } else if (['Bac+1', 'Bac+2', 'Bac+3', 'Bac+4', 'Bac+5', 'Bac+6', 'Mastère Spé', 'MBA', 'Doctorat'].includes(studyLevel)) {
      const tagify = new Tagify(specialties, {
        whitelist: ["Licence universitaire", "Master universitaire", "Licence professionnelle", "École d'hôtellerie", "École d'architecture", "Classe préparatoire", "DCG", "BUT/DEUST", "IEP/Sciences Politiques", "Ecole de droit", "ENM", "MANAA", "École supérieur d'Art", "École de commerce", "École média et communication", "École d'ingénieurs", "Ecole d'informatique", "Paramédicale et Social", "CAP/BEP/DMA", "BTS", "Fonction publique", "Armée", "MBA"],
        maxTags: 10,
        dropdown: {
          maxItems: 50,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false
        },
        enforceWhitelist: true,
        originalInputValueFormat: (valuesArr) =>
          valuesArr
            .map((item) => {
              return item.value;
            })
            .join(','),
      })
    } else {
      const tagify = new Tagify(specialties, {
        whitelist: [""],
        maxTags: 10,
        dropdown: {
          maxItems: 50,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false
        },
        enforceWhitelist: true,
        originalInputValueFormat: (valuesArr) =>
          valuesArr
            .map((item) => {
              return item.value;
            })
            .join(','),
      })
    }
  }
}
