import { Controller } from "stimulus";


export default class extends Controller {
    static targets = ["slides", "previousButton", "nextButton", "finishButton", "bullet"]
    static values = { index: Number }

    connect() {
        this.indexValue = 0;
        this.display();
    }

    getBullets() {
        this.bulletTarget.innerHTML = "";
        const size = this.slidesTarget.children.length;
        const template = document.createElement("div");
        template.className = "bullet";
        for (let i = 0; i < size ; i++) {
            const templateDiv = template.cloneNode(true);
            if (i === this.indexValue) {
                templateDiv.className = "bullet bullet__full"
            }
            this.bulletTarget.appendChild(templateDiv);
        }
    }

    next() {
        this.indexValue++;
        this.display();
    }

    displayPreviousButton() {
        const size = this.slidesTarget.children.length
        this.previousButtonTarget.hidden = (size === 1 || this.indexValue === 0);
    }

    displayFinishButton() {
        const size = this.slidesTarget.children.length
        if (size === 1 || this.indexValue + 1 === size) {
            this.finishButtonTarget.hidden = false;
            this.nextButtonTarget.hidden = true;
        } else {
            this.finishButtonTarget.hidden = true;
            this.nextButtonTarget.hidden = false;
        }
    }
    previous() {
        this.indexValue--;
        this.display();
    }

    refreshButtonDisplay() {
        this.displayPreviousButton();
        this.displayFinishButton();
        if (this.indexValue === 0) {
            this.nextButtonTarget.classList.add('tutorial-action-grid--middle');
        } else {
            this.nextButtonTarget.classList.remove('tutorial-action-grid--middle');
        }
    }

    display() {
        for (let i = 0; i < this.slidesTarget.children.length; i++) {
            this.slidesTarget.children[i].hidden = i !== this.indexValue;
        }
        this.refreshButtonDisplay();
        this.getBullets();
    }
}
