import { Controller } from "stimulus"

export default class extends Controller {


  connect() {
    const form = document.querySelector('form')
    const startDate= document.querySelector('#start_date')
    const endDate= document.querySelector('#end_date')
    const lastWeek = document.querySelector('#last_week')
    const lastMonth = document.querySelector('#last_month')
    const DSBD = this.dateSubstractByDays
    endDate.addEventListener('change', function() {
      form.submit()
    });

    startDate.addEventListener('change', function() {
      form.submit();
    });

    lastWeek.addEventListener('click', function() {
      startDate.value = DSBD(7)
      endDate.value = DSBD(0)
      form.submit();
    })

    lastMonth.addEventListener('click', function() {
      startDate.value = DSBD(31)
      endDate.value = DSBD(0)
      form.submit();
    })
  }

  dateSubstractByDays(days){
    return new Date(Date.now()- (days*24*60*60*1000)).toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }).replace(/\//g, "-");
  }

}
